import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "clearButton"]

  connect() {
    this.toggleClearButtonVisibility()
  }

  clearForSearchInputs(event) {
    const clearButton = event.currentTarget;
    const selectedSearchInput = clearButton.closest('.search-container').querySelector('input[type="search"]');

    if (selectedSearchInput) {
      selectedSearchInput.value = '';
      this.toggleClearButtonVisibility();
    }
  }

  toggleClearButtonVisibility() {
    // Timeout fixes clear button not displaying
    // due to input caching from page navigation in chrome
    setTimeout(() => {
      const input = this.inputTarget;
      const clearButton = this.clearButtonTarget;

      if (input.value) {
        clearButton.style.display = 'inline-block';
      } else {
        clearButton.style.display = 'none';
      }
    }, 1)
  }
}
